<template>
  <div class='allot'>
    <div class="action">
      <div>
        <span>角色名称：</span>
        <el-input v-model="roleName" placeholder="输入角色名称/角色类型" size="small" @change="inquireName" clearable>
        </el-input>
        <el-button type="primary" size="small" @change="inquireName">查 询</el-button>
      </div>
      <el-button type="success" size="small" @click="$router.push('/system/authority')">角色列表</el-button>
    </div>
    <el-alert type="info" show-icon>
      <template slot="title">
        列表记录总计：<span>{{total}}</span> 条
      </template>
    </el-alert>
    <el-table :data="tableData" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" border size="small">
      <el-table-column prop="name" label="角色名称">
      </el-table-column>
      <el-table-column prop="value" label="角色类型">
      </el-table-column>
      <el-table-column prop="action" label="操作">
        <template slot-scope="{row}">
          <el-button type="text" @click="chooseRole(row)" style="color:blue">
            选择权限
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination :total="total" layout="prev, pager, next" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
    <!-- 选择权限弹框 -->
    <el-dialog :visible.sync="roleVisible" title="选择权限" width="30%" append-to-body :lock-scroll="false">
      <el-tree :props="props" :default-checked-keys="defaultKeys" node-key="key" ref="tree" :data="roleData" show-checkbox @check-change="handleCheckChange" :default-expanded-keys="expandedKeys">
      </el-tree>
      <div slot="footer" class="dialogBtn">
        <el-button @click="roleVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveRole">保 存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPermissionList, getPermission, editPermission } from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      roleName: '',
      totalList: [],
      total: 0,
      tableData: [],
      queryParams: {
        pageSize: 10,
        pageNum: 1
      },
      // 角色弹框
      roleVisible: false,
      props: {
        children: 'children',
        label: 'title'
      },
      defaultKeys: [],
      expandedKeys: [],
      roleData: [],
      roleId: '',
      rolesName: ''
    };
  },
  created () {
    this.init()
    this.getTree()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['realname'])
  },
  methods: {
    // ...this.$refs.tree.getHalfCheckedKeys(), ...this.$refs.tree.getCheckedKeys()
    // 获取列表
    async init () {
      const res = await getPermissionList({ name: this.roleName })
      this.totalList = res
      this.total = res.length
      this.getList()
      // console.log('res :>> ', this.totalList, this.total);
    },
    // 获取所有权限
    async getTree () {
      const res = await getPermission()
      this.roleData = res.children
    },
    // 分页操作
    getList () {
      this.tableData = []
      let start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      let end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }
      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
      // console.log('tableData :>> ', this.tableData);
    },
    // 查询用户
    inquireName () {
      this.queryParams.pageSize = 10
      this.queryParams.pageNum = 1
      this.init()
    },
    // 选择权限
    chooseRole (val) {
      // console.log('val :>> ', val.split(','));

      // console.log('this.defaultKeys :>> ', this.defaultKeys);
      this.roleId = val.id
      this.rolesName = val.name
      this.expandedKeys = []
      this.roleVisible = true
      this.$nextTick(() => {
        //Dom更新完毕
        this.$refs.tree.setCheckedKeys([]);
        const defaultKeys = val.permission.split(',')
        // console.log('this.defaultKeys :>> ', this.defaultKeys);
        const arr = []
        defaultKeys.forEach(item => {
          // console.log('object :>> ', this.$refs.tree.getNode(item));
          if (this.$refs.tree.getNode(item)) {
            if (!this.$refs.tree.getNode(item).childNodes || !this.$refs.tree.getNode(item).childNodes.length) {
              arr.push(item)
            }
          }
        })
        // console.log('arr :>> ', arr);
        // this.$refs.tree.setCheckedKeys(arr)
        this.defaultKeys = arr
      });

    },
    // 选择权限
    handleCheckChange (data) {
      console.log(data)
    },
    // 保存权限
    async saveRole () {
      await editPermission(
        {
          roleId: this.roleId,
          menuIds: [...this.$refs.tree.getHalfCheckedKeys(), ...this.$refs.tree.getCheckedKeys()].join(',')
        }
      )
      this.$message.success('修改权限成功')
      this.$add(
        {
          userName: this.realname,
          module: '权限管理',
          content: `${this.realname}修改了${this.rolesName}角色的权限`,
          type: 5,
          platform: 8,
          operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
        }
      )
      this.init()
      // console.log('111 :>> ', 111);
      this.roleVisible = false
      // console.log('object :>> ', this.$refs.tree.getHalfCheckedKeys(), this.$refs.tree.getCheckedKeys());
    },
    // 表格居中
    headStyle () {
      return "text-align:center"
    },
    rowStyle () {
      return "text-align:center"
    }
  }
};
</script>

<style scoped lang="scss">
.allot {
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    > div {
      span {
        font-size: 16px;
        margin-right: 10px;
      }
      .el-input {
        width: 300px;
        margin-right: 10px;
      }
    }
  }
}
</style>
